@import "bootstrap.less";

/* ==========================================================================
   Base
   ========================================================================== */

body {
   font-family: 'Open Sans', sans-serif;
}

a, a:hover, a:focus, a:visited, a:active {
   outline: none !important;
}

p, h1, h2, h3, ul { text-rendering: optimizelegibility !important; }

/* ==========================================================================
   Header
   ========================================================================== */

.banner {
   width: 100%;
   height: 480px;
   display: table;
   background: #fff url('../img/techniwax-9265-shell-lmp.jpg') top right no-repeat;
   vertical-align: middle;
   text-align: center;
   position: relative;
   border-bottom: 1px solid #000;
}

.banner .caption {
   display: table-cell;
   vertical-align: middle;
}

.banner .caption h1 {
   border-bottom: 4px solid #000;
   display: inline-block;
   margin-top: 0px;
   font-weight: 800;
   padding-bottom: 5px;
}

.banner .caption h1 span {
   color: @brand-primary;
   font-weight: 700;
}

.banner .caption h1 small {
   font-weight: 400;
   font-size: 0.55em;
   color: #000;
}

h2.section-title {
   color: @brand-primary;
   font-weight: 700;
   font-size: 1.6em;
   margin-top: 40px;
   margin-bottom: 24px;
}

.banner-button {
   text-transform: uppercase;
   position: absolute;
   bottom: 0px;
   left: 50%;
   font-size: 0.9em;
   background: @brand-primary;
   padding: 14px 24px;
   color: #fff;
   width: 180px;
   margin-left: -90px;
   -webkit-border-radius: 4px 4px 0px 0px;
   -moz-border-radius: 4px 4px 0px 0px;
   border-radius: 4px 4px 0px 0px;
}

.banner-button:hover, .banner-button:visited, .banner-button:active, .banner-button:focus {
   background: darken(@brand-primary,4%);
   color: #fff;
   text-decoration: none;
}

.navigation {
   position: absolute;
   width: 100%;
   text-align: left;
}

.navbar-brand {
   padding-top: 0px;
}

.logo {
   opacity: 0.9;
}

.navbar-nav > li > a {
   color: #000;
   font-weight: 700;
   line-height: 40px;
   text-transform: uppercase;
   font-size: 0.9em;
}

.navbar-nav > li > a:hover, .navbar-nav > li > a:focus {
   background: transparent;
}

/* ==========================================================================
   Content
   ========================================================================== */

.section-content {
   font-size: 0.8em;
}

.section-content ul {
   padding-left: 0px;
   list-style-type: none;
   padding-top: 20px;
}

.section-content ul li {
   padding-left: 20px;
   background: transparent url('../img/arw.png') top left no-repeat;
   line-height: 12px;
   margin-bottom: 8px;
}

section {
   padding-bottom: 45px;
}

#request-sample-techniwax {
   background: -webkit-gradient(linear, left top, right top, from(#d51b22), to(#ec1d25));
   background: -webkit-linear-gradient(top, #d51b22, #ec1d25);
   background: -moz-linear-gradient(top, #d51b22, #ec1d25);
   background: -o-linear-gradient(top, #d51b22, #ec1d25);
   background: -ms-linear-gradient(top, #d51b22, #ec1d25);
   background: linear-gradient(top, #d51b22, #ec1d25);
   background-color: #d51b22;
   color: #fff;
}

#request-sample-techniwax .section-title {
   color: #fff;
}

#request-sample-techniwax ul {
   padding-top: 10px;
   list-style-type: disc;
   padding-left: 10px;
}

#request-sample-techniwax ul li {
   padding-left: 10px;
}

#request-sample-techniwax .alert {
   margin-bottom: 0px;
   margin-top: 20px;
}

#request-form {
   padding-top: 20px;
}

.form-horizontal .control-label {
   text-align: left;
}

#request-form input {
   border-radius: 0px;
}

#request-form .btn, #request-form .btn:hover {
   border:1px solid #fff;
   background: @brand-primary;
   color: #fff;
   min-width: 140px;
   text-align: left;
}

.control-label {
   padding-top: 14px;
}

.control-label:first-child {
   padding-top: 0px;
}

#request-form .btn:hover {
   background: darken(@brand-primary,5%);
}

#request-form span {
   padding-left: 22px;
}

#about-kerax a {
   font-weight: 700;
   color: #000;
}

/* ==========================================================================
   Primary Content
   ========================================================================== */

.main { }



/* ==========================================================================
   Posts
   ========================================================================== */

.hentry header { }
.hentry time { }
.hentry .byline { }
.hentry .entry-content { }
.hentry footer { }



/* ==========================================================================
   Footer
   ========================================================================== */

.content-info { }

footer {
   background: #333;
   color: #fff;
   padding: 34px 0px;
}

.copyrights {
   font-size: 0.6em;
}

footer#footer .social {
   display: inline-block;
   width: 100%;
   text-align: center;
   margin: 20px auto;
}

footer#footer .social {
   padding-left: 0px;
}

footer#footer .social li {
   float: left;
   width: 45px;
   list-style-type: none;
}

footer#footer .social li a {
   text-align: center;
   --webkit-border-radius: 50%;
   -moz-border-radius: 50%;
   border-radius: 50%;
   border: 2px solid white;
   padding: 6px;
   width: 35px;
   height: 35px;
   display: block;
   vertical-align: center;
   -webkit-transition: 0.2s ease-in;
   -moz-transition: 0.2s ease-in;
   -ms-transition: 0.2s ease-in;
   -o-transition: 0.2s ease-in;
   transition: 0.2s ease-in;
}

footer#footer .social li a:hover {
   border-color: #ec1d25;
   -webkit-transition: 0.2s ease-in;
   -moz-transition: 0.2s ease-in;
   -ms-transition: 0.2s ease-in;
   -o-transition: 0.2s ease-in;
   transition: 0.2s ease-in;
   text-decoration: none;
}

footer#footer .social li i {
   color: white;
}

/* ==========================================================================
   Media Queries
   ========================================================================== */

@media (min-width: @screen-sm-min) {

   .navbar-brand {
      padding-left: 0px;
   }

   .control-label {
      padding-top: 0px;
   }
   .control-label:first-child {
      padding-top: 7px;
   }

}

@media (min-width: @screen-md-min) {


}

@media (min-width: @screen-lg-min) { }
